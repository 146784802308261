import cx from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import { getLocale, formatDate, formatDates } from '../../helpers/utils/date';
import { languages, LanguageType } from '../../languages';

export const VARIANT_OPTIONS = {
  brand: 'Brand',
  action: 'Action',
  success: 'Success',
  alert: 'Alert',
  neutral: 'Neutral',
  ghost: 'Ghost',
  white: 'white',
  'neutral-gradient': 'Neutral gradient',
  'brand-gradient': 'Brand gradient',
};

export type VariantType = keyof typeof VARIANT_OPTIONS;

const variantClasses: Record<VariantType, string> = {
  brand: 'pyxis-badge-brand',
  action: 'pyxis-badge-action',
  success: 'pyxis-badge-success',
  alert: 'pyxis-badge-alert',
  neutral: 'pyxis-badge-neutral',
  ghost: 'pyxis-badge-ghost',
  white: 'pyxis-badge-white',
  'neutral-gradient': 'pyxis-badge-neutral-gradient',
  'brand-gradient': 'pyxis-badge-brand-gradient',
};

type BadgeProps = {
  label?: string;
  labelIsDate?: boolean;
  className?: string;
  variant?: VariantType;
  alt?: boolean;
  locale?: LanguageType;
};

export const Badge = ({
  variant,
  label,
  labelIsDate,
  alt,
  className,
  locale,
}: BadgeProps) => {
  const router = useRouter();

  if (!locale) locale = router?.locale as LanguageType;

  return (
    <span
      className={cx(
        'pyxis-badge',
        variantClasses[variant],
        {
          ['pyxis-badge-alt']: alt,
        },
        className,
      )}
    >
      {labelIsDate === true ? formatDates(label, getLocale(locale)) : label}
    </span>
  );
};
