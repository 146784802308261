export const isValidDate = (date) => {
  return !isNaN(date.getTime());
};

export const formatDate = (
  datetime: string,
  format?: Intl.DateTimeFormatOptions,
  locale?: string,
) => {
  const date = new Date(datetime);
  if (!isValidDate(date)) return datetime;
  return date.toLocaleDateString(
    locale || 'en-US',
    format || {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
  );
};

export const formatDates = (
  dates?: string | [string, string],
  locale?: string,
  showTime = false,
) => {
  // console.log('locale', locale);
  const extraDateOptions: Intl.DateTimeFormatOptions = showTime
    ? { hour: '2-digit', minute: '2-digit' }
    : {};

  if (Array.isArray(dates)) {
    const startDate = dates[0];
    const endDate = dates[1];

    // both start and end date
    if (startDate && endDate) {
      return `${formatDate(
        startDate,
        {
          day: 'numeric',
          month: 'short',
          ...extraDateOptions,
        },
        locale,
      )} - ${formatDate(
        endDate,
        {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          ...extraDateOptions,
        },
        locale,
      )}`;
    }

    // only start date
    if (startDate && !endDate) {
      return formatDate(
        startDate,
        {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          ...extraDateOptions,
        },
        locale,
      );
    }

    // only end date
    if (!startDate && endDate) {
      return formatDate(
        endDate,
        {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          ...extraDateOptions,
        },
        locale,
      );
    }

    // basic string
  } else {
    let isDate = false;

    // 2022-11-05 is a valid format
    if (/\d{4}-\d{2}-\d{2}/.test(dates)) isDate = true;

    // 1 Nov 2022 is a valid format
    if (/\d{1,2}\s\w+\s\d{4}/.test(dates)) isDate = true;

    if (!isDate) return dates;

    // basic date
    return formatDate(
      dates,
      {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        ...extraDateOptions,
      },
      // 'en-Gb',
      locale,
    );
  }
};

// get the date format
export const getLocale = (language) => {
  let locale;
  if (language === 'en') {
    locale = 'en-GB';
  } else if (language === 'es') {
    locale = 'es-ES';
  } else if (language === 'it') {
    locale = 'it-IT';
  }
  return locale;
};
