import cx from 'classnames';
import Link from 'next/link';
import React from 'react';

import { backgroundClasses, borderClasses } from '../../colors';
import { Badge, VariantType } from '../../components/Badge/Badge';
import { ButtonProps } from '../../components/buttons/Button';
import { ButtonGroup } from '../../components/buttons/ButtonGroup';
import { IconLoader } from '../../components/images/IconLoader';
import { ResponsiveImage } from '../../components/images/ResponsiveImage';
import { SizeType as TextSizeType, Text } from '../../components/module/Text';
import {
  SizeType as TitleSizeType,
  Title,
  WeightType,
} from '../../components/module/Title';
import { formatDates } from '../../helpers/utils/date';
import { truncate } from '../../helpers/utils/string';
import { CountryType, IconType, ImageType, TagType } from '../../types';
import {
  CardAlignType,
  CardBackgroundColorType,
  BorderColorType,
  IconColorType,
  IconSizeType,
  CardEffectType,
  ImageHeightType,
  ImageRatioType,
  ImageRoundedType,
  TextColorType,
  TitleColorType,
  CardSpacingType,
  ComposableCardThemeType,
} from './ComposableCardOptions';

export type ComposableCardProps = {
  type?: 'card.composable';
  cover?: ImageType;
  image?: ImageType;
  icon?: IconType;
  badge?: string;
  title?: string;
  subtitle?: string;
  text?: React.ReactElement | string;
  buttons?: ButtonProps[];
  themeName?: ComposableCardThemeType;
  tags?: TagType[];
  countries?: CountryType[];
  sourceImage?: ImageType;
  PressReleaseLogoField?: ImageType;
  theme?: {
    card?: {
      align?: CardAlignType;
      background?: CardBackgroundColorType;
      shadow?: boolean;
      border?: BorderColorType;
      effect?: CardEffectType;
      spacing?: CardSpacingType;
    };
    image?: {
      ratio?: ImageRatioType;
      height?: ImageHeightType;
      rounded?: ImageRoundedType;
    };
    icon?: {
      size?: IconSizeType;
      color?: IconColorType;
    };
    badge?: {
      variant?: VariantType;
      alt?: boolean;
    };
    title?: {
      size?: TitleSizeType;
      color?: TitleColorType;
      weight?: WeightType;
    };
    subtitle?: {
      size?: TitleSizeType;
      color?: TitleColorType;
      weight?: WeightType;
    };
    text?: {
      size?: TextSizeType;
      color?: TextColorType;
    };
    buttons?: {
      hidden?: boolean;
    };
  };
};

const ratioClasses: Record<ImageRatioType, string> = {
  auto: '',
  '16/9': 'aspect-[16/9]',
  '1/1': 'aspect-[1/1]',
  '3/2': 'aspect-[3/2]',
};

const iconSizeClasses: Record<IconSizeType, string> = {
  sm: 'w-14 h-14',
  lg: 'w-20 h-20',
};

const imageSizeClasses: Record<ImageHeightType, string> = {
  sm: 'w-20',
  md: 'w-24',
  lg: 'w-[210px]',
  xl: 'w-[230px]',
};

const roundedClasses: Record<ImageRoundedType, string> = {
  none: '',
  sm: 'rounded-lg',
  md: 'rounded-xl',
  lg: 'rounded-2xl',
  xl: 'rounded-3xl',
  full: 'rounded-full',
};

const cardBottomSpacingClasses: Record<CardSpacingType, string> = {
  md: 'pb-5 sm:pb-6 md:pb-8 lg:pb-10',
  lg: 'pb-10 sm:pb-12 md:pb-16 lg:pb-20',
};

const cardTopSpacingClasses: Record<CardSpacingType, string> = {
  md: 'pt-5 sm:pt-6 md:pt-8 lg:pt-10',
  lg: 'pt-10 sm:pt-12 md:pt-16 lg:pt-20',
};

export const COMPOSABLE_CARD_THEMES: Record<
  ComposableCardThemeType,
  ComposableCardProps['theme']
> = {
  department: {
    card: { border: 'neutral-85' },
    title: { size: 'lg', color: 'neutral-base' },
    text: { size: 'md', color: 'neutral-25' },
    image: { height: 'sm', ratio: '1/1', rounded: 'full' },
  },
  pressrelease: {
    card: { background: 'neutral-95' },
    text: { size: 'md', color: 'neutral-25' },
    badge: { variant: 'brand-gradient' },
    title: { size: 'xs', color: 'neutral-base', weight: 'book' },
    subtitle: { size: 'md', color: 'neutral-base' },
  },
  event: {
    card: { background: 'neutral-95' },
    text: { size: 'md', color: 'neutral-25' },
    badge: { variant: 'brand-gradient' },
    title: { size: 'xs', color: 'neutral-base', weight: 'book' },
    subtitle: { size: 'md', color: 'neutral-base' },
  },
  mediacoverage: {
    card: { background: 'neutral-95' },
    text: { size: 'md', color: 'neutral-25' },
    badge: { variant: 'brand-gradient' },
    title: { size: 'xs', color: 'neutral-base', weight: 'bold' },
    subtitle: { size: 'md', color: 'neutral-base' },
  },
  person: {
    title: { size: 'md', color: 'brand-base' },
    subtitle: { size: 'md', color: 'neutral-base', weight: 'book' },
    image: { rounded: 'xl', ratio: '3/2', height: 'lg' },
    text: { color: 'neutral-25', size: 'lg' },
  },
};

export const ComposableCard = ({
  badge,
  title,
  subtitle,
  text,
  image,
  cover,
  icon,
  theme,
  themeName,
  buttons,
  tags,
  countries,
  sourceImage,
  PressReleaseLogoField,
}: ComposableCardProps) => {
  const cardClickable = buttons?.length === 1 && buttons?.[0]?.href;

  console.log('badge', badge);

  if (themeName && COMPOSABLE_CARD_THEMES[themeName]) {
    theme = COMPOSABLE_CARD_THEMES[themeName];
  }

  // add white background when there is a shadow
  if (theme?.card?.shadow && !theme?.card?.background)
    theme.card.background = 'white';

  // add white background when there is a border and clickable
  if (cardClickable && theme?.card?.border && !theme?.card?.background)
    theme.card.background = 'white';

  const hasContent =
    badge || subtitle || title || text || icon || image || Boolean(buttons?.length);

  const hasTagsOrCountries =
    Boolean(countries?.filter(Boolean).length) ||
    Boolean(tags?.filter(Boolean).length);
  const hasCountries = Boolean(countries?.filter(Boolean).length);
  const hasTags = Boolean(tags?.filter(Boolean).length);

  return (
    <div className="h-full relative group">
      {/* overlay to make the entire card clickable */}
      {cardClickable && (
        <Link href={buttons?.[0]?.href}>
          <a className="absolute inset-0 z-10 opacity-0">
            <span className="sr-only">{buttons?.[0].label}</span>
          </a>
        </Link>
      )}

      <div
        className={cx(
          'flex flex-col relative gap-4 overflow-hidden h-full',
          backgroundClasses[theme?.card?.background],
          borderClasses[theme?.card?.border],
          {
            ['text-left']: theme?.card?.align === 'left',
            ['text-center']: theme?.card?.align === 'center',
            ['text-right']: theme?.card?.align === 'right',
            ['border-2']: theme?.card?.border,
            ['rounded-2xl md:rounded-3xl']:
              theme?.card?.background || theme?.card?.border || theme?.card?.shadow,
            ['drop-shadow-[10px_16px_5px_rgba(89,93,106,0.05)] md:drop-shadow-[10px_16px_32px_rgba(89,93,106,0.15)]']:
              theme?.card?.shadow,
            ['transition-drop-shadow group-hover:drop-shadow-[10px_16px_5px_rgba(89,93,106,0.05)] md:group-hover:drop-shadow-[10px_16px_32px_rgba(89,93,106,0.15)]']:
              cardClickable,
          },
        )}
      >
        {cover && (
          <div
            className={cx('relative overflow-hidden w-full aspect-video', {
              ['-mb-4']:
                theme?.card?.background ||
                theme?.card?.border ||
                theme?.card?.shadow,
              ['grayscale group-hover:grayscale-0 transition-all']:
                theme?.card?.effect === 'grayscale',
            })}
          >
            <div
              className={cx('absolute inset-0', {
                ['transition-transform ease-out-cubic group-hover:scale-110 duration-500 origin-center']:
                  cardClickable,
              })}
            >
              <ResponsiveImage {...cover} layout="fill" />
            </div>
          </div>
        )}

        {hasContent && (
          <div
            className={cx('flex gap-4 flex-col z-1 relative', {
              'px-5 sm:px-6 md:px-7 lg:px-8 2xl:px-10':
                theme?.card?.border ||
                theme?.card?.background ||
                theme?.card?.shadow,
              [cardTopSpacingClasses[theme?.card?.spacing || 'md']]:
                theme?.card?.border ||
                theme?.card?.background ||
                theme?.card?.shadow,
              [cardBottomSpacingClasses[theme?.card?.spacing || 'md']]:
                (theme?.card?.border ||
                  theme?.card?.background ||
                  theme?.card?.shadow) &&
                (!buttons || theme?.buttons?.hidden),
              ['h-full']: !cover,
            })}
          >
            {image && (
              <div
                className={cx('inline', {
                  ['grayscale group-hover:grayscale-0 transition-all']:
                    theme?.card?.effect === 'grayscale',
                })}
              >
                <div
                  className={cx(
                    'relative inline-flex overflow-hidden max-w-full',
                    imageSizeClasses[theme?.image?.height || 'sm'],
                    ratioClasses[theme?.image?.ratio || '16/9'],
                  )}
                  style={{
                    aspectRatio:
                      theme?.image?.ratio === 'auto' || !theme?.image?.height
                        ? `${image?.width} / ${image?.height}`
                        : null,
                  }}
                >
                  <ResponsiveImage
                    layout="fill"
                    {...image}
                    className={roundedClasses[theme?.image?.rounded]}
                  />
                </div>
              </div>
            )}

            {icon && (
              <div className="mb-4 md:mb-7 lg:mb-9 xl:mb-11">
                <IconLoader
                  icon={icon}
                  color={theme?.icon?.color || 'brand-base'}
                  title={['icon', icon, theme?.icon?.color]
                    .filter(Boolean)
                    .join(' ')}
                  className={cx(
                    'inline-block',
                    iconSizeClasses[theme?.icon?.size || 'sm'],
                    {
                      ['grayscale group-hover:grayscale-0 transition-all']:
                        theme?.card?.effect === 'grayscale',
                    },
                  )}
                />
              </div>
            )}
            {themeName && themeName === 'pressrelease' ? (
              <div className="flex gap-2 flex-row items-center">
                {badge && (
                  <div className="flex-[1_0_auto]">
                    <Badge
                      label={badge}
                      labelIsDate={true}
                      variant={theme?.badge?.variant}
                      alt={theme?.badge?.alt}
                      className={cx({
                        ['opacity-80 saturate-0 group-hover:saturate-100 group-hover:opacity-100 transition-all']:
                          theme?.card?.effect === 'grayscale',
                      })}
                    />
                  </div>
                )}
                {title && (
                  <div className="flex-[1_1_100%]">
                    <Title
                      as="h3"
                      color={theme?.title?.color}
                      size={theme?.title?.size}
                      weight={theme?.title?.weight}
                      className={cx({
                        ['group-hover:underline']:
                          cardClickable && theme?.card?.effect !== 'grayscale',
                        ['opacity-80 brightness-0 group-hover:brightness-100 group-hover:opacity-100 transition-all']:
                          theme?.card?.effect === 'grayscale',
                      })}
                    >
                      {title}
                    </Title>
                  </div>
                )}
                {PressReleaseLogoField?.src && (
                  <div className="h-[26px] w-full">
                    <ResponsiveImage
                      src={PressReleaseLogoField.src}
                      alt={PressReleaseLogoField.alt || 'Default Alt Text'}
                      layout={'fixed'}
                    />
                  </div>
                )}
              </div>
            ) : (
              <>
                {badge && (
                  <div>
                    <Badge
                      label={badge}
                      labelIsDate={
                        themeName === 'mediacoverage'
                          ? true
                          : themeName === 'event'
                          ? true
                          : false
                      }
                      variant={theme?.badge?.variant}
                      alt={theme?.badge?.alt}
                      className={cx({
                        ['opacity-80 saturate-0 group-hover:saturate-100 group-hover:opacity-100 transition-all']:
                          theme?.card?.effect === 'grayscale',
                      })}
                    />
                  </div>
                )}
                {title && (
                  <Title
                    as="h3"
                    color={theme?.title?.color}
                    size={theme?.title?.size}
                    weight={theme?.title?.weight}
                    className={cx({
                      ['group-hover:underline']:
                        cardClickable && theme?.card?.effect !== 'grayscale',
                      ['opacity-80 brightness-0 group-hover:brightness-100 group-hover:opacity-100 transition-all']:
                        theme?.card?.effect === 'grayscale',
                    })}
                  >
                    {title}
                  </Title>
                )}
              </>
            )}
            {subtitle && (
              <Title
                as="span"
                color={theme?.subtitle?.color}
                size={theme?.subtitle?.size}
                weight={theme?.subtitle?.weight}
                className={cx({
                  ['opacity-80 brightness-0 group-hover:brightness-100 group-hover:opacity-100 transition-all']:
                    theme?.card?.effect === 'grayscale',
                  ['-mt-4 -mb-1']: themeName === 'person',
                })}
              >
                {subtitle}
              </Title>
            )}

            {hasTagsOrCountries && (
              <div className="flex flex-wrap gap-x-2 gap-y-2 pb-6 md:pb-0 md:pr-10 mt-1">
                {hasCountries &&
                  countries.map(({ title }) => (
                    <Badge variant="brand" label={title} key={title} alt />
                  ))}
                {hasTags &&
                  tags.map(({ title }) => (
                    <Badge variant="action" label={title} key={title} alt />
                  ))}
              </div>
            )}

            {text && (
              <Text
                as="div"
                size={theme?.text?.size || 'md'}
                color={theme?.text?.color || 'neutral-25'}
                align={theme?.card?.align}
                className={cx('underline-links break-words', {
                  ['opacity-75']: theme?.text?.color === 'white',
                  ['mb-3 md:mb-0']: themeName === 'person',
                })}
              >
                {themeName === 'pressrelease' ? truncate(text || '', 120) : text}
              </Text>
            )}
          </div>
        )}
        {buttons && (
          <ButtonGroup
            items={buttons}
            stretch={false}
            direction="vertical"
            className={cx('mt-auto px-5 sm:px-6 md:px-7 lg:px-8 2xl:px-10 pt-2', {
              [cardBottomSpacingClasses[theme?.card?.spacing || 'md']]:
                theme?.card?.border ||
                theme?.card?.background ||
                theme?.card?.shadow,
              ['opacity-80 saturate-0 group-hover:saturate-100 group-hover:opacity-100 transition-all']:
                theme?.card?.effect === 'grayscale',
              ['hidden']: theme?.buttons?.hidden,
            })}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(ComposableCard);
